body {
  margin: 0;
  font-family: 'Cairo', sans-serif;
  font-size: 1.1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.recharts-legend-item-text {
  font-weight: bold;
}

.component-card {
  border: 1px solid lightgray;
  background-color: white;
  margin: 1rem;
  padding: 1.5rem;
}

.p-0 {
  padding: 0 !important;
}
