.public-key-wrapper .public-key-partial {
    display: flex;
}

.public-key-wrapper:hover .public-key-partial {
    display: none;
}

.public-key-wrapper .public-key-full {
    display: none;
    text-decoration: none;
    line-height: 1.5;
    font-size: 1rem;
}

.public-key-wrapper:hover .public-key-full {
    display: inline;
}

.public-key-wrapper .public-key-copy {
    font-size: 1.2em;
    margin-left: 0.5rem;
}

.public-key-wrapper:hover .public-key-copy {
    display: inline;
    cursor: pointer;
}