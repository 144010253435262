.arrow-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrow-back-icon {
  margin-bottom: 0.2em;
  margin-right: 1rem;
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

