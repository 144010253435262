.w-100 {
  width: 100%;
}


h2 {
  font-size: 1.1em;
  font-weight: bold;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}
