li.nav-item {
    margin-top: 0.5rem;
}

li.nav-item:hover {
    background-color: #edf1ff;
    border-radius: 10px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #0226b5;
    background-color: #ecf2fe;
}

.nav-pills .nav-link {
    font-weight: 500;
}

.bi {
    vertical-align: -.125em;
    pointer-events: none;
    fill: currentColor;
}

.dropdown-toggle {
    outline: 0;
}

.nav-flush .nav-link {
    border-radius: 0;
}


.fw-semibold {
    font-weight: 600;
}

.lh-tight {
    line-height: 1.25;
}